import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { QueryData } from '../../../shared/models/payloads/query-data.payload';

import { environment } from '../../../../environments/environment';
import {
  FeedbackResult,
  ListFeedbacksResult,
  TotalUnreadFeedbackAnswersResult,
} from '../../../shared/models/api-results/feedback.api-result';
import {
  AddCourseFeedbackPayload,
  AddEcosActionFeedbackPayload,
  AddFeedbackAnswerPayload,
  AddQuestionFeedbackPayload,
  UpdateFeedbackPayload,
  WFeedbacksQueryData,
} from '../../../shared/models/payloads/feedback.payload';
import { prepareQueryParams } from '../../../shared/utils/api-transformations';
import { AddedEntity } from '../../../shared/models/api-results/base.api-result';

@Injectable({
  providedIn: 'root',
})
export class HttpFeedbackService {
  constructor(private http: HttpClient) {}

  list(params: QueryData) {
    return this.http
      .get(`${environment.api_base_url}/feedbacks/me/list_my_feedbacks`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListFeedbacksResult));
  }

  addCourseFeedback(payload: AddCourseFeedbackPayload) {
    return this.http.post(`${environment.api_base_url}/feedbacks/add_course_feedback`, payload);
  }

  addQuestionFeedback(payload: AddQuestionFeedbackPayload) {
    return this.http.post(`${environment.api_base_url}/feedbacks/add_question_feedback`, payload);
  }

  addEcosActionFeedback(payload: AddEcosActionFeedbackPayload) {
    return this.http.post(
      `${environment.api_base_url}/feedbacks/add_ecos_action_feedback`,
      payload
    );
  }

  get(id: string) {
    return this.http
      .get(`${environment.api_base_url}/feedbacks/${id}/feedback_detail_for_student`)
      .pipe(map((res) => res as FeedbackResult));
  }

  read(id: string) {
    return this.http.post(
      `${environment.api_base_url}/feedback_answers/${id}/mark_feedback_answer_as_read`,
      {}
    );
  }

  answer(payload: AddFeedbackAnswerPayload) {
    return this.http
      .post(`${environment.api_base_url}/feedback_answers/add_student_feedback_answer`, payload)
      .pipe(map((res) => res as AddedEntity));
  }

  listFeedbacksOfQuestion(id: string) {
    return this.http
      .get(`${environment.api_base_url}/feedbacks/me/list_feedbacks_of_the_question/${id}`)
      .pipe(map((res) => res as ListFeedbacksResult));
  }

  listFeedbacksOfFolder(id: string) {
    return this.http
      .get(
        `${environment.api_base_url}/feedbacks/me/list_published_feedbacks_of_completed_folder/${id}`
      )
      .pipe(map((res) => res as ListFeedbacksResult));
  }

  listFeedbacksOfExamContentQuestions(id: string) {
    return this.http
      .get(
        `${environment.api_base_url}/feedbacks/me/list_feedbacks_of_the_isolated_questions_of_an_exam_content/${id}`
      )
      .pipe(map((res) => res as ListFeedbacksResult));
  }

  listFeedbacksOfEcosSection(id: string) {
    return this.http
      .get(
        `${environment.api_base_url}/feedbacks/me/list_the_feedbacks_of_the_actions_of_an_ecos_section_i_finished_at_least_once/${id}`
      )
      .pipe(map((res) => res as ListFeedbacksResult));
  }

  listFeedbacksOfCustomExam(id: string) {
    return this.http
      .get(
        `${environment.api_base_url}/feedbacks/list_published_feedbacks_of_custom_exam_i_created_and_finished/${id}`
      )
      .pipe(map((res) => res as ListFeedbacksResult));
  }

  countTotalUnreadFeedbackAnswers() {
    return this.http
      .get(
        `${environment.api_base_url}/feedback_answers/me/how_many_unread_feedback_answers_i_have`
      )
      .pipe(map((res) => res as TotalUnreadFeedbackAnswersResult));
  }

  wGet(id: string) {
    return this.http
      .get(`${environment.api_base_url}/feedbacks/${id}/feedback_detail`)
      .pipe(map((res) => res as FeedbackResult));
  }

  wListMyOwn(params: WFeedbacksQueryData) {
    return this.http
      .get(`${environment.api_base_url}/feedbacks/me/list_feedback_associated_to_content_i_own`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListFeedbacksResult));
  }

  wListAll(params: WFeedbacksQueryData) {
    return this.http
      .get(`${environment.api_base_url}/feedbacks/me/list_feedback`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListFeedbacksResult));
  }

  wUpdate(id: string, payload: UpdateFeedbackPayload) {
    return this.http.post(
      `${environment.api_base_url}/feedbacks/${id}/update_feedback_category`,
      payload
    );
  }

  wDelete(id: string) {
    return this.http.post(`${environment.api_base_url}/feedbacks/${id}/delete_feedback`, {});
  }

  wUpdateIsPrivate(id: string, isPrivate: boolean) {
    return this.http.post(
      `${environment.api_base_url}/feedbacks/${id}/update_feedback_private_status`,
      {
        isPrivate,
      }
    );
  }

  wAddFeedbackAnswer(payload: AddFeedbackAnswerPayload) {
    return this.http
      .post(`${environment.api_base_url}/feedback_answers/reply_to_feedback_answer`, payload)
      .pipe(map((res) => res as AddedEntity));
  }
}
